define('knowme-client/adapters/application', ['exports', 'knowme-client/config/environment', 'ember-simple-auth-token/mixins/token-adapter', 'ember-data/adapters/rest', 'ember-inflector', 'ember-data-url-templates', 'lodash'], function (exports, _environment, _tokenAdapter, _rest, _emberInflector, _emberDataUrlTemplates, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { scheme, host, namespace, version } = _environment.default.APP.API;

  exports.default = _rest.default.extend(_tokenAdapter.default, _emberDataUrlTemplates.default, {
    coalesceFindRequests: true,
    authorizer: 'authorizer:jwt',
    session: Ember.inject.service(),
    namespace: [namespace, version].join('/'),
    hostUrlTemplate: '{+scheme}://{+host}',
    urlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{/id}{?view_as}',
    findManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,paginate,account_id}',
    queryUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?ids[],view_as}',
    urlSegments: {
      scheme: scheme,
      host() {
        return host || document.location.host;
      },
      view_as() {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return this.get('session.impersonatedUser.id');
        }
      },
      account_id(type, id, snapshot, query = {}) {
        if (Ember.isPresent(query.account_id)) {
          return query.account_id;
        }
      },
      paginate: function (type, id, snapshot, query) {
        if (Ember.isPresent(query) && query.paginate === true) {
          delete query.paginate;
          return true;
        }
      }
    },
    handleResponse: function (status, headers, payload, requestData) {
      if (this.isSuccess(...arguments)) {
        if (!Ember.isNone(payload) && Ember.isBlank(payload.meta)) {
          payload.meta = {};
        }
        if (!Ember.isNone(payload) && Ember.isBlank(payload.meta.pagination)) {
          payload.meta.pagination = {};
        }
        //IE10+ is parsing headers capitalized
        if (!Ember.isNone(payload) && (Ember.isPresent(headers["x-total"]) || Ember.isPresent(headers["X-Total"]))) {
          if (!isNaN(parseInt(headers["x-total"], 10))) {
            payload.meta.pagination.total = parseInt(headers["x-total"], 10);
            payload.meta.pagination.page = parseInt(headers["x-page"], 10);
            payload.meta.pagination.limit = parseInt(headers["x-per-page"], 10);
          } else {
            payload.meta.pagination.total = parseInt(headers["X-Total"], 10);
            payload.meta.pagination.page = parseInt(headers["X-Page"], 10);
            payload.meta.pagination.limit = parseInt(headers["X-Per-Page"], 10);
          }
        }
      } else if (this.isInvalid(...arguments)) {
        let errors = [];
        Ember.A(Object.keys(payload.errors)).forEach(function (key) {
          errors.push({
            detail: payload.errors[key],
            source: {
              pointer: `data/attributes/${key}`
            }
          });
        });
        payload.errors = errors;
      }
      if (status == 401 && _lodash.default.includes(requestData.url, _environment.default.APP.email_service.host)) {
        return { status: status, headers: headers, payload: payload, data: requestData };
      } else {
        return this._super(status, headers, payload, requestData);
      }
    },
    normalizeErrorResponse: function (status, headers, payload) {
      if (payload && typeof payload === "object" && payload.errors) {
        return payload.errors;
      } else {
        return [{
          status: "" + status,
          title: "The backend responded with an error",
          detail: "" + payload.error
        }];
      }
    },
    pathForType: function (type) {
      if (Ember.isNone(type)) {
        type = '';
      }
      if (type.indexOf('/') > -1) {
        return (0, _emberInflector.pluralize)(type.split('/')[0]) + '/' + type.split('/')[1];
      }
      return (0, _emberInflector.pluralize)(type).underscore();
    },
    // https://github.com/amiel/ember-data-url-templates/issues/47
    sortQueryParams(params) {
      return params;
    }
  });
});
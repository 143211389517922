define('knowme-client/components/user/resource-form', ['exports', 'knowme-client/mixins/controllers/status', 'ember-concurrency', 'ember-uploader/uploaders'], function (exports, _status, _emberConcurrency, _uploaders) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_status.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service('authorized-ajax'),
    isSaving: false,
    isUploading: false,
    openConfirmRelease: false,
    receiverTypeOptions: [{ value: 'direct_number', text: 'Direct Phone #' }],
    toggleRolePermissions: 'hide',
    receiverOptions: Ember.computed('receiverTypeOptions', 'model.activeDevices.length', function () {
      let options = Ember.copy(this.get('receiverTypeOptions'));
      options.push({ value: 'knowme_iq_number', text: 'Knowme IQ # (Rings Mobile App)', disabled: this.get('model.activeDevices.length') == 0 });
      return options;
    }),
    showExitButton: Ember.computed('model', function () {
      var name = Ember.getOwner(this).lookup('controller:application').currentPath;
      return name === 'wizard.users';
    }),
    showUpdateButton: Ember.computed('model', function () {
      var name = Ember.getOwner(this).lookup('controller:application').currentPath;
      return name === 'profile';
    }),
    isProfilePage: Ember.computed.alias('showUpdateButton'),
    init() {
      this._super(...arguments);
      if (Ember.isEmpty(this.get('model.role'))) {
        this.get('model').set('role', this.get('roles').findBy('name', 'Manager'));
      }
      this.loadOwnedLocalNumbers();
    },
    loadedOwnedLocalNumbers: false,
    user: Ember.computed.alias('model'),
    searchDids: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(800);

      let dids = Ember.A([]);

      dids.pushObject(this.get('ownedLocalNumbers'));
      dids.pushObject(this.searchLocalNumbers(term));

      return Ember.RSVP.all(dids).then(function (response) {
        return [{
          groupName: 'Released Numbers',
          options: response[0]
        }, {
          groupName: 'New Numbers',
          options: response[1]
        }];
      });
    }),
    ownedLocalNumbersOptions: Ember.computed('ownedLocalNumbers.length', function () {
      return [{
        groupName: 'Released Numbers',
        options: this.get("ownedLocalNumbers")
      }];
    }),
    searchLocalNumbers(term) {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      let didsUrl = adapter.buildURL('dids');

      let didsRequest = adapter.ajax(didsUrl, 'GET', { data: { type: 'local', filter: 'unassigned', query: term, show_as: 'numbers', live: true } });
      return didsRequest.then(function (dids) {
        return dids.map(function (did) {
          return did.number;
        });
      });
    },
    loadOwnedLocalNumbers() {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      let didsUrl = adapter.buildURL('dids');

      let didsRequest = adapter.ajax(didsUrl, 'GET', { data: { type: 'local', filter: 'unassigned,user_allocatable', show_as: 'numbers' }, traditional: true });
      return didsRequest.then(function (dids) {
        return dids.map(function (did) {
          return did.number;
        });
      }).then(function (numbers) {
        this.set('ownedLocalNumbers', numbers);
        return numbers;
      }.bind(this));
    },
    actions: {
      upload: function (event) {
        var downloadingImage = new Image();
        let controller = this;
        const files = event.target.files;
        if (files) {
          let url = Ember.getOwner(this).lookup('adapter:user').buildURL('users', this.get('model.id'), null, null, {});
          const uploader = _uploaders.Uploader.create({ url: url, method: 'PUT' });
          controller.set('isUploading', true);
          let ajaxSettings = { headers: this.get('ajax.headers') };
          uploader.set('ajaxSettings', ajaxSettings);
          uploader.upload(files[0]).then(resp => {
            controller.get("store").pushPayload('user', resp);
            downloadingImage.src = this.get('model.avatarThumbnailUrl');
            downloadingImage.onload = function () {
              controller.set('isUploading', false);
            };
          }, error => {
            controller.set('isUploading', false);
            controller.get('flashMessages').danger(error.responseJSON.error, { destroyOnClick: true });
          });
        }
      },
      resetIfEmpty: function (fieldName, event) {
        if (event.type === 'keyup' && event.which === 8 || event.type === 'focusout') {
          if (fieldName === 'password') {
            if (Ember.isEmpty(this.get('model.password'))) {
              // rollbackAttribute
              this.get('model').set('password', null);
              if (Ember.isEmpty(this.get('model.passwordConfirmation'))) {
                // rollbackAttribute
                this.get('model').set('passwordConfirmation', null);
              }
            } else {
              if (Ember.isBlank(this.get('model.passwordConfirmation'))) {
                // trigger validation
                this.get('model').set("passwordConfirmation", "");
              }
            }
          } else if (fieldName === 'passwordConfirmation') {
            if (Ember.isEmpty(this.get('model.passwordConfirmation')) && Ember.isEmpty(this.get('model.password'))) {
              // rollbackAttribute
              this.get('model').set('passwordConfirmation', null);
            }
          }
        }
      },
      copyToClip: function (number) {
        function listener(e) {
          e.clipboardData.setData("text/html", number);
          e.clipboardData.setData("text/plain", number);
          e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
        this.get('flashMessages').info("Knowme IQ Number is copied", { destroyOnClick: true, timeout: 3000 });
      },
      toggleRolePermissions: function () {
        let klass = this.get('toggleRolePermissions') === 'hide' ? 'show' : 'hide';
        this.set('toggleRolePermissions', klass);
      },
      toggleHowTo: function () {
        this.sendAction('toggleHowTo');
      },
      update: function () {
        let controller = this;
        let resource = controller.get('model');
        resource.one('didUpdate', function () {
          controller.get('store').peekAll('users-dashboard-widget').filter(function (_model) {
            return _model.get('isNew');
          }).invoke('deleteRecord');
          controller.get('store').peekAll('users-dashboard-widget').filter(function (_model) {
            return _model.get('isDeleted');
          }).invoke('unloadRecord');
          controller.get('store').peekAll('users-dashboard-widget').filter(function (_model) {
            return _model && _model.get('hasDirtyAttributes');
          }).invoke('reload');
        }.bind(this));

        // internalModel.prototype.setId does not work for currentUser
        resource._internalModel.setId = function setId(id) {
          true && !(this.id === null || this.id === id || this.isNew()) && assert('A record\'s id cannot be changed once it is in the loaded state', this.id === null || this.id === id || this.isNew());

          Ember.set(this, "id", id);
          if (this._record.get('id') !== id) {
            this._record.set('id', id);
          }
        };
        resource.save().then(function (res) {
          if (Ember.isPresent(resource.get('didAllocation.id')) || Ember.isPresent(resource.get('didAllocation.didNumber'))) {
            resource.belongsTo('didAllocation').reload();
          }

          if (Ember.isPresent(this.get('afterSave'))) {
            this.sendAction('afterSave', resource);
          }
          controller.sendAction('updateUsersDashboardList');
        }.bind(this)).catch(function (reason) {
          console.log("Errors", reason);
          this.get('flashMessages').warning(reason.errors[0].detail, { destroyOnClick: true, timeout: 12000 });
        }.bind(this));
      },
      submit: function (step) {
        let account = this.get('session.currentUser.account');
        let model = this.get('model');
        if (step === 'exit') {
          account.set('currentWizardStep', 'complete');
          this.get('router').transitionTo('dashboard');
        } else {
          // internalModel.prototype.setId does not work for currentUser
          model._internalModel.setId = function setId(id) {
            true && !(this.id === null || this.id === id || this.isNew()) && assert('A record\'s id cannot be changed once it is in the loaded state', this.id === null || this.id === id || this.isNew());

            Ember.set(this, "id", id);
            if (this._record.get('id') !== id) {
              this._record.set('id', id);
            }
          };
          this.set('isSaving', true);
          model.save().then(function (resource) {
            if (Ember.isPresent(resource.get('didAllocation.id')) || Ember.isPresent(resource.get('didAllocation.didNumber'))) {
              resource.belongsTo('didAllocation').reload();
            }

            if (step === 'close') {
              this.get('router').transitionTo('account.users', { queryParams: { status: model.get('status') } });
            } else {
              this.get('router._router._routerMicrolib').refresh();
            }
            this.set('isSaving', false);
          }.bind(this)).catch(function (reason) {
            console.log("Errors", reason);
            this.set('isSaving', false);
            this.get('flashMessages').warning(reason.errors[0].detail, { destroyOnClick: true, timeout: 12000 });
          }.bind(this));
        }
      },
      release() {
        this.get('model.didAllocation').then(function (didAllocation) {
          didAllocation.set('status', 'inactive');
          didAllocation.save().then(function () {
            this.set('openConfirmRelease', false);
            this.get('router._router._routerMicrolib').refresh();
          }.bind(this));
        }.bind(this));
      }
    },
    hasUsersDashboardWidgetsChanged: Ember.computed('model.usersDashboardWidgetsHasChanged', 'model.usersDashboardWidgets.@each.isDeleted', 'model.usersDashboardWidgets.@each.hasDirtyAttributes', function () {
      if (Ember.getOwner(this).lookup('controller:application').currentPath != 'profile') {
        return false;
      }

      let orderChanged = this.get('model.usersDashboardWidgets').any(function (udw) {
        return udw.get('hasDirtyAttributes');
      });
      let hasDeletedWidgets = this.get('model.usersDashboardWidgets').any(function (udw) {
        return udw.get('isDeleted');
      });
      let hasAddedWidgets = this.get('model.usersDashboardWidgetsHasChanged');

      return hasAddedWidgets || hasDeletedWidgets || orderChanged;
    }),
    isLastAdminUser: Ember.computed('model', 'session.currentUser.account', function () {
      return !this.get('model.isNew') && this.get('session.currentUser.account.singleAdmin') && (this.get('model.isAdmin') || this.get('model.isClientAdmin'));
    }),
    isModelTreeDirty: Ember.computed('model.hasDirtyAttributes', 'model.rolesHasChanged', 'model.didAllocation.didNumberHasChanged', function () {
      return this.get('model.hasDirtyAttributes') || this.get('model.rolesHasChanged') || this.get('model.didAllocation.didNumberHasChanged');
    }),
    disabledCallerId: Ember.computed('model', 'model.didAllocation.did', 'model.didAllocation.didNumberHasChanged', function () {
      return !this.get('model.isNew') && !this.get('model.didAllocation.didNumberHasChanged') && this.get('model.didAllocation.did');
    }),
    disabledRole: Ember.computed('model', 'session', 'session.currentUser', function () {
      return !this.get('session.currentUser.hasAdminRole') || this.get('isLastAdminUser');
    }),
    disabledStatus: Ember.computed.alias('disabledRole'),
    disableSubmit: Ember.computed('model', 'model.validations.isValid', 'model.didAllocation.didNumber', 'isSaving', 'isModelTreeDirty', 'hasUsersDashboardWidgetsChanged', function () {
      return this.get('isSaving') || !(this.get('model.validations.isValid') && this.get('isModelTreeDirty')) && !this.get('hasUsersDashboardWidgetsChanged');
    }),
    showDeallocateButton: Ember.computed('model.didAllocation.status', function () {
      return Ember.isPresent(this.get('model.didAllocation')) && this.get('model.didAllocation.isActive') && this.get('session.currentUser.hasAdminRole');
    })
  });
});
define('knowme-client/controllers/customer-experience', ['exports', 'knowme-client/mixins/date-filter', 'knowme-client/mixins/controllers/queryable', 'knowme-client/mixins/controllers/pagination', 'ember-cli-file-saver/mixins/file-saver', 'lodash', 'moment'], function (exports, _dateFilter, _queryable, _pagination, _fileSaver, _lodash, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dateFilter.default, _queryable.default, _pagination.default, _fileSaver.default, {
    ajax: Ember.inject.service('authorized-ajax'),
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    incentivesController: Ember.inject.controller('account.settings.incentives'),
    reviewsController: Ember.inject.controller('account.settings.reviews'),
    queryParams: ['filter', 'satisfaction', 'user_id', 'contact_id', 'lead_id', 'survey_status'],
    defaultsQueryParams: {
      filter: null,
      satisfaction: null,
      user_id: null,
      contact_id: null,
      lead_id: null,
      survey_status: 'prompt'
    },
    converted: null,
    filter: null,
    user_id: null,
    lead_id: null,
    contact_id: null,
    satisfaction: null,
    survey_status: null,
    promptOption: 'prompt',
    satisfactionFilterOptions: ['none', 'unhappy', 'very_dissatisfied', 'dissatisfied', 'neutral', 'satisfied', 'very_satisfied', 'happy', 'improved', 'regressed'],
    surveyFilterOptions: ['none', 'survey_requested', 'responded_to_survey', 'no_response', 'calls_graded_by_user', 'calls_graded_by_caller'],
    incentivesSelected: false,
    reviewsSelected: false,
    opportunityShowController: Ember.inject.controller('leads.show.opportunities.show'),
    onSearchByName: Ember.observer('dateFilterSelected', 'filter', 'user_id', 'query', 'survey_status', 'satisfaction', function () {
      this.set('page', null);
    }),
    availableUsersFilter: Ember.computed('session', 'session.currentUser', function () {
      return !this.get('session').get('currentUser.isSalesPerson');
    }),
    filters: Ember.computed('accountService.customersText', function () {
      let customersText = this.get('accountService.customersText');
      return [{ id: 'leads', name: 'All Leads' }, { id: 'customers', name: `All ${customersText}` }];
    }),
    filteredModel: Ember.computed.filter('model', function (resource) {
      if (Ember.isPresent(this.get('selectedResource'))) {
        return this.get('selectedResource.id') === resource.get('id');
      } else {
        return true;
      }
    }).property('model', 'selectedResource'),
    processDateFilterParams: function (params) {
      return _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === 'from_date' || key === 'to_date' || key === 'on_date' || key === 'converted_at') {
          return (0, _moment.default)(value, "MM-DD-YYYY").format('DD-MM-YYYY');
        }
        return value;
      });
    },
    serialize: function (obj, prefix) {
      var str = [],
          p;
      for (p in obj) {
        if (obj.hasOwnProperty(p)) {
          var k = prefix ? prefix + "[" + p + "]" : p,
              v = obj[p];
          str.push(v !== null && typeof v === "object" ? this.serialize(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
      }
      return str.join("&");
    },
    actions: {
      filteredCustomerExperiences: function (resource) {
        let options = { contact_id: resource.get('id'), user_id: this.get('user_id') };

        if (Ember.isPresent(this.get('query'))) {
          options.query = this.get('query');
        }
        if (Ember.isPresent(this.get('satisfaction')) && this.get('satisfaction') !== 'prompt') {
          options.satisfaction = this.get('satisfaction');
        }
        if (Ember.isPresent(this.get('survey_status')) && this.get('survey_status') !== 'prompt') {
          options.survey_status = this.get('survey_status');
        }
      },
      startListening: function () {
        this.send('pauseRefreshing');
      },
      stopListening: function () {
        this.send('continueRefreshing');
      },
      export: function () {
        let controller = this;
        let params = _lodash.default.merge(this.get('params'), { paginate: false, format: 'csv', processData: false, dataType: 'text' });
        let urlQuery = this.serialize(params);
        let adapter = Ember.getOwner(this).lookup('adapter:contact');
        let url = adapter.buildURL('contact', null, null, "query", params);
        url = url + '?' + urlQuery;

        let csvRequest = controller.get('ajax').request(url, { method: "GET", processData: false, dataType: 'blob' });
        csvRequest.then(content => controller.saveFileAs('customer-experiences.csv', content, 'csv'));
      }
    }
  });
});
define('knowme-client/models/contact', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'knowme-client/mixins/models/validations/contact', 'knowme-client/mixins/models/ember-cp-validations-custom-properties', 'knowme-client/mixins/dirty', 'knowme-client/mixins/models/status', 'knowme-client/models/customizable-resource'], function (exports, _model, _attr, _relationships, _contact, _emberCpValidationsCustomProperties, _dirty, _status, _customizableResource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Contact;
  Contact = _customizableResource.default.extend(_contact.default, _emberCpValidationsCustomProperties.default, _dirty.default, _status.default, {
    firstName: (0, _attr.default)(),
    lastName: (0, _attr.default)(),
    title: (0, _attr.default)(),
    primary: (0, _attr.default)('boolean', { defaultValue: false }),
    contactInfos: (0, _relationships.hasMany)('contact-info', { async: true }),
    lead: (0, _relationships.belongsTo)('lead', { async: true }),
    calls: (0, _relationships.hasMany)('call', { async: true }),
    customerExperiences: (0, _relationships.hasMany)('customer-experience', { async: true }),
    salesTransactions: (0, _relationships.hasMany)('sales-transaction', { async: true }),
    lastCustomerExperience: (0, _relationships.belongsTo)('customer-experience', { async: true }),
    shortMessages: (0, _relationships.hasMany)('short-message', { async: true }),
    lastCall: (0, _relationships.belongsTo)('call', { async: true }),
    lastShortMessage: (0, _relationships.belongsTo)('short-message', { async: true }),
    incentivesContacts: (0, _relationships.hasMany)('incentives-contact', { async: true, inverse: 'contact' }),
    incentivesContact: (0, _relationships.belongsTo)('incentives-contact', { async: true, inverse: 'respondedContacts' }),
    lastIncentivesContact: (0, _relationships.belongsTo)('incentives-contact', { async: true }),
    reviewLinksContacts: (0, _relationships.hasMany)('review-links-contact', { async: true }),
    incentivesCount: (0, _attr.default)('number'),
    callsCount: (0, _attr.default)('number'),
    shortMessagesCount: (0, _attr.default)('number'),
    opportunitiesCount: (0, _attr.default)('number'),
    scoredOpportunities: (0, _attr.default)('number'),
    contactId: (0, _attr.default)('number'),
    fullName: Ember.computed('firstName', 'lastName', function () {
      if (Ember.isBlank(this.get('firstName')) || Ember.isBlank(this.get('lastName'))) {
        return null;
      } else {
        return this.get('firstName') + ' ' + this.get('lastName');
      }
    }),
    firstNameAndInitialOfLastName: Ember.computed('firstName', 'lastName', function () {
      return `${this.get('firstName')} ${Ember.isPresent(this.get('lastName')) ? this.get('lastName')[0] : ''}.`;
    }),
    name: Ember.computed.alias('fullName'),
    fullNameOrPhoneNumber: Ember.computed('fullName', 'contactInfosContactPhoneNumbers.firstObject.formattedTenDigitNumber', function () {
      return Ember.isBlank(this.get('fullName')) ? this.get('contactInfosContactPhoneNumbers.firstObject.formattedTenDigitNumber') : this.get('fullName');
    }),
    fullNameOrEmail: Ember.computed('fullName', 'contactInfosContactEmails.firstObject.email', function () {
      return Ember.isBlank(this.get('fullName')) ? this.get('contactInfosContactEmails.firstObject.email') : this.get('fullName');
    }),
    fullNameOrUnknown: Ember.computed('fullName', 'contactInfosContactPhoneNumbers', function () {
      return Ember.isBlank(this.get('fullName')) ? 'Unknown' : this.get('fullName');
    }),
    contactInfosContactEmails: Ember.computed('contactInfos.@each.contactEmails.[]', function () {
      return this.get('contactInfos').reduce(function (previousValue, contactInfo) {
        return previousValue.pushObjects(contactInfo.get('contactEmails').toArray());
      }, Ember.A([]));
    }),
    contactInfosContactPhoneNumbers: Ember.computed('contactInfos.@each.contactPhoneNumbers.[]', function () {
      return this.get('contactInfos').reduce(function (previousValue, contactInfo) {
        return previousValue.pushObjects(contactInfo.get('contactPhoneNumbers').toArray());
      }, Ember.A([]));
    }),
    contactInfosContactAddresses: Ember.computed('contactInfos.@each.contactAddresses.[]', function () {
      return this.get('contactInfos').reduce(function (previousValue, contactInfo) {
        return previousValue.pushObjects(contactInfo.get('contactAddresses').toArray());
      }, Ember.A([]));
    }),
    firstContactPhoneNumber: Ember.computed('contactInfosContactPhoneNumbers.firstObject.id', function () {
      return this.get('contactInfosContactPhoneNumbers').map(function (contactInfoContactPhoneNumbers) {
        return contactInfoContactPhoneNumbers;
      }).compact().get('firstObject');
    }),
    firstContactEmail: Ember.computed('contactInfosContactEmails.firstObject.id', function () {
      return this.get('contactInfosContactEmails').map(function (contactInfoContactEmails) {
        return contactInfoContactEmails;
      }).compact().get('firstObject');
    }),
    homeContactInfos: Ember.computed.filter('contactInfos', function (contactInfo /*, index, array*/) {
      return contactInfo.get('category') === 'home';
    }),
    workContactInfos: Ember.computed.filter('contactInfos', function (contactInfo /*, index, array*/) {
      return contactInfo.get('category') === 'work';
    }),
    mobileContactInfos: Ember.computed.filter('contactInfos', function (contactInfo /*, index, array*/) {
      return contactInfo.get('category') === 'mobile';
    }),
    billingContactInfos: Ember.computed.filter('contactInfos', function (contactInfo /*, index, array*/) {
      return contactInfo.get('category') === 'billing';
    }),
    shippingContactInfos: Ember.computed.filter('contactInfos', function (contactInfo /*, index, array*/) {
      return contactInfo.get('category') === 'shipping';
    })
  });

  exports.default = Contact;
});
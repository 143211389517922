define('knowme-client/components/outbound-call-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    features: Ember.inject.service(),
    router: Ember.inject.service(),
    email: Ember.inject.service('email-connect'),
    accountService: Ember.inject.service('account'),
    called: false,
    calling: false,
    smsFormVisible: false,
    lead: null,
    opportunity: null,
    selectedLead: Ember.computed.oneWay('lead'),
    selectedContact: null,
    selectedPhoneNumber: null,
    selectedOpportunity: Ember.computed.oneWay('opportunity'),
    outboundCallPhoneNumber: Ember.computed.oneWay('session.currentUser.outboundCallPhoneNumber'),
    autoCall: false,
    isContactActionRendering: false,
    isRequestSent: false,
    isRequestMessagesLoaded: false,
    attachments: [],
    startConversationCommands: ['restart', 'start'],
    userHasKnowmeIQ: Ember.computed('session.currentUser.didAllocation.did.phoneNumber', function () {
      return Ember.isPresent(this.get('session.currentUser.didAllocation.did.phoneNumber'));
    }),
    firstActivity: Ember.computed('selectedOpportunity.firstActivity', function () {
      return this.get('selectedOpportunity.firstActivity');
    }),
    isSendingSurvey: false,
    didDestroyElement() {
      this.set('selectedPhoneNumber', null);
      this.set('selectedContact', null);
      this.set('isRequestMessagesLoaded', false);
      this.set('isRequestSent', false);
    },
    isDidReleased: Ember.computed('selectedOpportunity.ad.callTouchPoints.firstObject.didAllocation.status', function () {
      return this.get('selectedOpportunity.ad.callTouchPoints.firstObject.didAllocation.isInactive');
    }),
    onSelectedContactChange: Ember.observer('selectedContact.id', function () {
      if (Ember.isPresent(this.get('selectedContact.id'))) {
        this.set('called', false);
      }
      this.set('isRequestMessagesLoaded', false);
      this.set('isRequestSent', false);
    }),
    onCallStarted: Ember.observer('calling', function () {
      if (this.get('calling')) {
        Ember.run.debounce(this, Ember.set, this, 'calling', false, 12000);
      } else {
        this.set('called', true);
      }
    }),
    onDisplayShortMessageFormChange: Ember.observer('displayShortMessageForm', function () {
      if (this.get('displayShortMessageForm')) {
        this.set('shortMessage', this.get('store').createRecord('shortMessage', { attachments: [] }));
        this.set('attachments', []);
      } else {
        if (this.get('shortMessage.isNew')) {
          this.get('shortMessage').deleteRecord();
        }
      }
    }),
    highlightOpportunitySelector: Ember.computed('selectedContact', 'selectedOpportunity', function () {
      return Ember.isPresent(this.get('selectedContact')) && Ember.isBlank(this.get('selectedOpportunity'));
    }),
    didRender() {
      this._super(...arguments);
      if (this.get('autoCall')) {
        this.send('callTo', this.get('selectedPhoneNumber'));
      }
      if (this.get('autoText')) {
        this.set('displayShortMessageForm', true);
      }

      if (this.get('selectedContactEmail')) {
        this.send('emailTo', this.get('selectedContactEmail'));
        this.set('selectedContactEmail', null);
      }
    },
    shortMessageAttachments: Ember.computed.mapBy('attachments', 'signedId'),
    actions: {
      toggleShortMessageForm: function (contactPhoneNumber) {
        this.set('displayShortMessageForm', !this.get('displayShortMessageForm'));
        if (this.get('displayShortMessageForm')) {
          this.set('selectedPhoneNumber', contactPhoneNumber);
        } else {
          this.set('selectedPhoneNumber', null);
        }
      },
      reRenderContactActions: function () {
        let controller = this;
        controller.set('isContactActionRendering', true);
        Ember.run.later(function () {
          controller.set('isContactActionRendering', false);
        }, 5);
      },
      highlightOpportunitySelector: function (highlight) {
        if (highlight) {
          this.$('#outbound-communication-opportunity-selector .dropdown.bootstrap-select button.selected-option').addClass('highlight');
        } else {
          this.$('#outbound-communication-opportunity-selector .dropdown.bootstrap-select button.selected-option').removeClass('highlight');
        }
      },
      sendRequestPermissionShortMessage: function (contactPhoneNumber) {
        this.set('shortMessage', this.get('store').createRecord('shortMessage'));
        this.get('shortMessage').setProperties({ body: 'Sent Request for Permission to Text', contactPhoneNumber: contactPhoneNumber, opportunity: this.get('selectedOpportunity') });
        this.set('isRequestSent', true);
        this.get('shortMessage').save().then(function () {
          if (Ember.isPresent(this.get('selectedLead').then)) {
            this.get('selectedLead').then(function (lead) {
              lead.reload();
            });
          } else {
            this.get('selectedLead').reload();
          }
          Ember.run(function () {
            this.get('selectedOpportunity').reload();
            this.get('selectedOpportunity.activities').reload();
            contactPhoneNumber.reload();
          }.bind(this));
        }.bind(this), function (response) {
          console.log(response);
          this.set('isRequestSent', false);
          this.get('flashMessages').warning(response.errors[0].detail, { destroyOnClick: true, timeout: 12000 });
        }.bind(this));
      },
      addAttachmentToShortMessage: function (blob) {
        this.get('attachments').pushObject(blob);
      },
      removeAttachment: function (attachment) {
        this.get('attachments').removeObject(attachment);
      },
      shortMessageTo: function () {
        this.get('shortMessage').setProperties({
          contactPhoneNumber: this.get('selectedPhoneNumber'),
          opportunity: this.get('selectedOpportunity'),
          attachments: this.get('shortMessageAttachments')
        });
        this.get('shortMessage').save().then(function () {
          if (Ember.isPresent(this.get('selectedLead').then)) {
            this.get('selectedLead').then(function (lead) {
              lead.reload();
            });
          } else {
            this.get('selectedLead').reload();
          }
          Ember.run(function () {
            this.get('selectedOpportunity').reload();
            this.get('selectedOpportunity.activities').reload();
          }.bind(this));
        }.bind(this), function (response) {
          this.get('flashMessages').warning(response.errors[0].detail, { destroyOnClick: true, timeout: 12000 });
        }.bind(this)).finally(function () {
          this.set('selectedPhoneNumber', null);
          this.set('autoText', false);
          Ember.run.later(function () {
            this.send('toggleShortMessageForm');
          }.bind(this), 500);
        }.bind(this));
      },
      emailTo: function (selectedEmail) {
        if (this.get('selectedOpportunity')) {
          let data = {
            to: selectedEmail.get("email"),
            from: this.get("session.currentUser.email"),
            lead: this.get("selectedLead"),
            contact: this.get("selectedContact"),
            opportunity: this.get("selectedOpportunity"),
            knowmeCustomerId: this.get("session.currentUser").get('account').get('id'),
            isDraft: true
          };

          let draftMessage = this.get('store').createRecord('message', data);
          draftMessage.save().then((email => {
            this.set('draftMessage', email);
            this.set('selectedEmail', selectedEmail);
          }).bind(this));
        }
      },
      callTo: function (contactPhoneNumber) {
        this.set('autoCall', false);
        this.set('calling', true);

        if (Ember.isBlank(this.get('selectedPhoneNumber'))) {
          this.set('selectedPhoneNumber', contactPhoneNumber);
        }

        console.log("calling", this.get('selectedContact.fullName'), contactPhoneNumber.get('id'), contactPhoneNumber.get('formattedTenDigitNumber'), this.get('outboundCallPhoneNumber'), this.get('selectedLead.id'), this.get('selectedOpportunity.id'));

        let adapter = Ember.getOwner(this).lookup('adapter:application');

        let callsUrl = adapter.buildURL('calls');

        let selectedOpportunityId = this.get('selectedOpportunity.id');

        let ajaxOptions = {
          data: {
            contact_phone_number_id: contactPhoneNumber.get('id')
          }
        };
        if (Ember.isPresent(selectedOpportunityId)) {
          ajaxOptions['data']['opportunity_id'] = selectedOpportunityId;
        }
        if (Ember.isPresent(this.get('outboundCallPhoneNumber'))) {
          ajaxOptions['data']['route_to'] = this.get('outboundCallPhoneNumber');
        }
        let callRequest = adapter.ajax(callsUrl, 'post', ajaxOptions);

        callRequest.then(function (response) {
          if (Ember.isPresent(response.id)) {
            if (Ember.isPresent(selectedOpportunityId)) {
              Ember.$("#opportunity-history-table").collapse('show');
              if (Ember.isPresent(this.get('selectedLead').then)) {
                this.get('selectedLead').then(function (lead) {
                  lead.reload();
                });
              } else {
                this.get('selectedLead').reload();
              }

              this.get('selectedOpportunity').reload();
              this.get('selectedOpportunity.activities').then(function (activities) {
                Ember.$("#opportunity-communication").prop('disabled', true);
                var refreshedActivitiesLimit = 60,
                    refreshedActivitiesCount = 0;
                var refreshActivities = function () {
                  activities.reload();
                  refreshedActivitiesCount = refreshedActivitiesCount + 1;
                  if (refreshedActivitiesCount < refreshedActivitiesLimit) {
                    Ember.run.later(function () {
                      refreshActivities();
                    }, 10000);
                  }
                };
                refreshActivities();
              });
            } else {
              this.get('router').transitionTo('leads.show.opportunities.show', [this.get('selectedLead'), response.opportunity_id]).then(function (transition) {
                transition.currentModel.set('disableSource', true);
                Ember.run.schedule('afterRender', this, function () {
                  Ember.$("#opportunity-history-table").collapse('show');
                });
              }.bind(this));
            }
            this.get('flashMessages').info("Your call has been scheduled and your phone will ring momentarily, please make sure your phone is available for an incoming call", { destroyOnClick: true, timeout: 12000 });
          }
        }.bind(this), function (response) {
          this.get('flashMessages').warning(response.errors[0].detail, { destroyOnClick: true, timeout: 12000 });
        }.bind(this));
      }
    }
  });
});
define('knowme-client/adapters/account-setting', ['exports', 'knowme-client/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    findManyUrlTemplate: '{+scheme}://{+host}/{+namespace}/{+pathForType}{?view_as,paginate,account_id}',
    urlSegments: {
      view_as: function () {
        if (Ember.isPresent(this.get('session.impersonatedUser'))) {
          return this.get('session.impersonatedUser.id');
        }
      },
      account_id: function (type, id, snapshot, query = {}) {
        return query.account_id;
      },
      paginate: function (type, id, snapshot, query = {}) {
        if (Ember.isArray(id) || Ember.isPresent(query.account_id)) {
          return false;
        }
        return true;
      }
    }
  });
});
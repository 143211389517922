define('knowme-client/models/did', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr'], function (exports, _model, _relationships, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    didAllocations: (0, _relationships.hasMany)('did-allocation', { async: true, inverse: 'did' }),
    didAllocation: (0, _relationships.belongsTo)('did-allocation', { async: true }),
    calls: (0, _relationships.hasMany)('calls', { async: true }),
    shortMessages: (0, _relationships.hasMany)('short-messages', { async: true }),
    phoneNumber: (0, _attr.default)(),
    formattedPhoneNumber: Ember.computed('phoneNumber', function () {
      if (Ember.isPresent(this.get('phoneNumber'))) {
        if (this.get('phoneNumber').match(/(\+\d+)(\d{3})(\d{3})(\d{4})/)) {
          return this.get('phoneNumber').replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
        } else {
          return this.get('phoneNumber');
        }
      }
    })
  });
});
define('knowme-client/controllers/contact', ['exports', 'knowme-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { contactPhoneNumber } = _environment.default.APP;

  exports.default = Ember.Controller.extend({
    scroller: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    profileController: Ember.inject.controller('profile'),
    isSent: false,
    isContactFormVisible: false,
    contactPhoneNumber: contactPhoneNumber,
    isContactHelpForm: Ember.computed.alias('session.isAuthenticated'),
    isContactUsForm: Ember.computed.not('isContactHelpForm'),
    requiredFields: ['first_name', 'last_name', 'email', 'phone_number|mobile_phone_number', 'subject', 'message'],
    disabled: Ember.computed('model.first_name', 'model.last_name', 'model.email', 'model.phone_number', 'model.mobile_phone_number', 'model.subject', 'model.message', function () {
      let res = false;
      this.get('requiredFields').forEach((fields, i) => {
        let condition = true;
        fields.split('|').forEach((field, i) => {
          condition = condition && Ember.isBlank(this.get(`model.${field}`));
        });
        res = res || condition;
      });
      return res;
    }),
    actions: {
      openDescriptionFor: function (e) {
        e.preventDefault();
        localStorage.setItem('howToItem', 'my_set_up');
        this.transitionToRoute('profile');
      },
      capitalize: function (fieldName) {
        let model = this.get('model');
        let value = model.get(fieldName);
        model.set(fieldName, value.capitalize());
      },
      openContactUsForm: function () {
        this.set('isContactFormVisible', true);
        Ember.run.later(this, function () {
          let target = Ember.$('.contact-us-form');
          target.find('input:first').focus();
        }, 300);
      },
      closeContactUsForm: function () {
        this.set('isContactFormVisible', false);
      },
      submitContactUsForm: function (model) {
        let adapter = Ember.getOwner(this).lookup('adapter:application');
        return adapter.ajax(adapter.buildURL(null, null, null, "host") + '/contact_us', "post", {
          data: model
        }).then(function () {
          this.set('model.first_name', '');
          this.set('model.last_name', '');
          this.set('model.email', '');
          this.set('model.account_id_number', '');
          this.set('model.subject', '');
          this.set('model.phone_number', '');
          this.set('model.message', '');
          this.set('isContactFormVisible', false);
          this.set('isSent', true);
        }.bind(this), function (res) {
          this.get('flashMessages').warning(res.errors[0].detail, { destroyOnClick: true, timeout: 12000 });
        }.bind(this));
      }
    }
  });
});